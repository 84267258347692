import { Brand } from "@/types/Brand";
import CarData from "@/types/LatestCar";
import { NavItem } from "@/types/Nav";
import { Product } from "@/types/Products";

export const navItem: NavItem[] = [
    {
        title: 'Home',
        link: '/',
        icon: 'home',
    },
    {
        title: 'Cars',
        link: '/car',
        icon: 'info',
    },
    {
        title: 'Used Car',
        link: '/usedcar',
        icon: 'contact_mail',
    },
    {
        title: 'News',
        link: '/news',
    },
    {
        title: 'Reviews and Video',
        link: '/reviews', 
    },
];

export const products1: Product[] = [
    {
        id: 1,
        name: 'BMW 3 Series',
        minPrice: 2000000,
        maxPrice: 3000000,
    },
    {
        id: 2,
        name: 'Audi A4',
        minPrice: 2500000,
        maxPrice: 3500000,
    },
    {
        id: 3,
        name: 'Mercedes-Benz C-Class',
        minPrice: 3000000,
        maxPrice: 4000000,
    },
    {
        id: 4,
        name: 'Tesla Model 3',
        minPrice: 3500000,
        maxPrice: 4500000,
    },
    {
        id: 5,
        name: 'Jaguar XE',
        minPrice: 2800000,
        maxPrice: 3700000,
    },
    {
        id: 6,
        name: 'Volvo S60',
        minPrice: 2700000,
        maxPrice: 3600000,
    },
    {
        id: 7,
        name: 'Lexus IS',
        minPrice: 3300000,
        maxPrice: 4200000,
    },
    {
        id: 8,
        name: 'Hyundai Elantra',
        minPrice: 1500000,
        maxPrice: 2200000,
    },
    {
        id: 9,
        name: 'Toyota Camry',
        minPrice: 2400000,
        maxPrice: 3300000,
    },
    {
        id: 10,
        name: 'Honda Accord',
        minPrice: 2100000,
        maxPrice: 2900000,
    }
];

export const brand: Brand[] = [
    {
        id: 1,
        name: "Maruti",
        
    },
    {
        id: 2,
        name: "Honda"
    },
    {
        id: 3,
        name: "Toyota"
    },
    {
        id: 4,
        name: "Hyundai"
    },
    {
        id: 5,
        name: "Ford"
    },
    {
        id: 6,
        name: "Chevrolet"
    },
    {
        id: 7,
        name: "Nissan"
    },
    {
        id: 8,
        name: "BMW"
    }
];

export const detailavItem : NavItem[]=[
    {
        title:"OverView",
        link:"/"
      },
      {
        title:"Features",
        link:"/"
      },
      {
        title:"Pricing",
        link:"/"
      }
    ]
// data/carData.ts

export const latestCarData: CarData[] = [
    {
      title: "New Maruti Dzire Is Available In 7 Monotone Colors",
      description:
        "It is spacious, comfortable and finally has its own identity, but some things were better the way they were before",
      author: "ABCD",
      date: "February 23, 2024",
    },
    {
        title: "New Maruti Dzire Is Available In 7 Monotone Colors",
        description:
          "It is spacious, comfortable and finally has its own identity, but some things were better the way they were before",
        author: "ABCD",
        date: "February 23, 2024",
      },
      {
        title: "New Maruti Dzire Is Available In 7 Monotone Colors",
        description:
          "It is spacious, comfortable and finally has its own identity, but some things were better the way they were before",
        author: "ABCD",
        date: "February 23, 2024",
      },
      {
        title: "New Maruti Dzire Is Available In 7 Monotone Colors",
        description:
          "It is spacious, comfortable and finally has its own identity, but some things were better the way they were before",
        author: "ABCD",
        date: "February 23, 2024",
      },
      {
        title: "New Maruti Dzire Is Available In 7 Monotone Colors",
        description:
          "It is spacious, comfortable and finally has its own identity, but some things were better the way they were before",
        author: "ABCD",
        date: "February 23, 2024",
      },
      {
        title: "New Maruti Dzire Is Available In 7 Monotone Colors",
        description:
          "It is spacious, comfortable and finally has its own identity, but some things were better the way they were before",
        author: "ABCD",
        date: "February 23, 2024",
      },
   
  ];

export const ProductDetailNavItem = [
  {
    title: "OverView",
    link: "overview"
  },
  {
    title: "Price",
    link: "price"
  },
  {
    title: "Images",
    link: "images"
  },
  {
    title: "Specs",
    link: "specs"
  },
  {
    title: "Variants",
    link: "variants"
  },
  {
    title: "Mileage",
    link: "mileage"
  },
  {
    title: "Colors",
    link: "colors"
  },
  // {
  //   title: "Dealers",
  //   link: "dealers"
  // },
  // {
  //   title: "Reviews",
  //   link: "reviews"
  // },
  // {
  //   title: "EMI",
  //   link: "emi"
  // }
]

export const DEFAULT_METADATA = {
  title: 'New Cars, Car Prices, Buy Cars, Reviews and Latest Update on Cars and Automobile in India.',
  description: 'You can find new car prices, reviews, and other useful information on Carly, which is becoming more popular. This helps you find the right car',
  siteName: 'carly.in',
  url: 'https://www.carly.in',
  logoImage: '/carly-logo-dark.png',
  metadataBase: 'https://www.carly.in',
}
