'use client';  // Client-side component

import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import logo from '../../../public/images/home/carlyLogo.png';
import Icon from '../icon';
import { navItem } from '../utils/Contant';

const Drawer = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <button title='Toggle Drawer' onClick={toggleDrawer} className="lg:hidden">
        <Icon name="hamburger" className="h-5 w-5 text-gray-500 dark:text-gray-400" />
      </button>

      {/* Drawer Overlay and Content */}
      {isDrawerOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={toggleDrawer}></div>
          <div className="fixed top-0 left-0 w-64 h-full bg-white z-50 transform translate-x-0 transition-transform duration-300 ease-in-out">
            <div className="p-4 flex justify-between items-center border-b">
              <Image src={logo} alt="Carly" width={42} height={41} className="w-5 lg:w-11 h-5 lg:h-11" />
              <button title='Close' onClick={toggleDrawer}>
                <Icon name="close" className="h-5 w-5 text-gray-500" />
              </button>
            </div>
            <nav className="p-4">
              {navItem.map((item, index) => (
                <Link key={index} href={item.link} className="block py-2 text-secondary-dark" onClick={toggleDrawer}>
                  {item.title}
                </Link>
              ))}
            </nav>
          </div>
        </>
      )}
    </>
  );
};

export default Drawer;
